@font-face {
  font-family: 'TTFirsNeue';
  font-display: 'swap';
  src: local('TTFirsNeue'), url(https://fonts.guiker.com/tt-firs-neue/TTFirsNeue-Regular.woff) format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'TTFirsNeue';
  font-display: 'swap';
  src: local('TTFirsNeue-Black'), url(https://fonts.guiker.com/tt-firs-neue/TTFirsNeue-Black.woff) format('woff');
  font-weight: 900;
}

@font-face {
  font-family: 'TTFirsNeue';
  font-display: 'swap';
  src: local('TTFirsNeue-Bold'), url(https://fonts.guiker.com/tt-firs-neue/TTFirsNeue-Bold.woff) format('woff');
  font-weight: 700;
}

@font-face {
  font-family: 'TTFirsNeue';
  font-display: 'swap';
  src: local('TTFirsNeue-DemiBold'), url(https://fonts.guiker.com/tt-firs-neue/TTFirsNeue-DemiBold.woff) format('woff');
  font-weight: 600;
}

@font-face {
  font-family: 'TTFirsNeue';
  font-display: 'swap';
  src: local('TTFirsNeue-ExtraBold'), url(https://fonts.guiker.com/tt-firs-neue/TTFirsNeue-ExtraBold.woff) format('woff');
  font-weight: 800;
}

@font-face {
  font-family: 'TTFirsNeue';
  font-display: 'swap';
  src: local('TTFirsNeue-ExtraLight'), url(https://fonts.guiker.com/tt-firs-neue/TTFirsNeue-ExtraLight.woff) format('woff');
  font-weight: 200;
}

@font-face {
  font-family: 'TTFirsNeue';
  font-display: 'swap';
  src: local('TTFirsNeue-Light'), url(https://fonts.guiker.com/tt-firs-neue/TTFirsNeue-Light.woff) format('woff');
  font-weight: 300;
}

@font-face {
  font-family: 'TTFirsNeue';
  font-display: 'swap';
  src: local('TTFirsNeue-Medium'), url(https://fonts.guiker.com/tt-firs-neue/TTFirsNeue-Medium.woff) format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'TTFirsNeue';
  font-display: 'swap';
  src: local('TTFirsNeue-Thin'), url(https://fonts.guiker.com/tt-firs-neue/TTFirsNeue-Thin.woff) format('woff');
  font-weight: 100;
}

@font-face {
  font-family: 'Gilroy';
  font-display: 'swap';
  src: local('Gilroy'), url(https://fonts.guiker.com/gilroy/Gilroy-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Gilroy-Bold';
  font-display: 'swap';
  src: local('Gilroy-Bold'), url(https://fonts.guiker.com/gilroy/Gilroy-Bold.woff) format('woff');
}

@font-face {
  font-family: 'Gilroy-Medium';
  font-display: 'swap';
  src: local('Gilroy-Medium'), url(https://fonts.guiker.com/gilroy/Gilroy-Medium.woff) format('woff');
}

@font-face {
  font-family: 'Gilroy-SemiBold';
  font-display: 'swap';
  src: local('Gilroy-SemiBold'), url(https://fonts.guiker.com/gilroy/Gilroy-SemiBold.woff) format('woff');
}

@font-face {
  font-family: 'Gilroy-ExtraBold';
  font-display: 'swap';
  src: local('Gilroy-ExtraBold'), url(https://fonts.guiker.com/gilroy/Gilroy-ExtraBold.woff) format('woff');
}

@font-face {
  font-family: 'Gilroy-Light';
  font-display: 'swap';
  src: local('Gilroy-Light'), url(https://fonts.guiker.com/gilroy/Gilroy-Light.woff) format('woff');
}

@font-face {
  font-family: 'Inter';
  font-display: 'swap';
  src: local('Inter'), url(https://fonts.guiker.com/inter/Inter-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Inter-Bold';
  font-display: 'swap';
  src: local('Inter-Bold'), url(https://fonts.guiker.com/inter/Inter-Bold.woff) format('woff');
}

@font-face {
  font-family: 'Inter-SemiBold';
  font-display: 'swap';
  src: local('Inter-SemiBold'), url(https://fonts.guiker.com/inter/Inter-SemiBold.woff) format('woff');
}

@font-face {
  font-family: 'Inter-Medium';
  font-display: 'swap';
  src: local('Inter-Medium'), url(https://fonts.guiker.com/inter/Inter-Medium.woff) format('woff');
}

@font-face {
  font-family: 'Inter-Light';
  font-display: 'swap';
  src: local('Inter-Light'), url(https://fonts.guiker.com/inter/Inter-Light.woff) format('woff');
}
